<template>
  <div class="settings-container">
    <setting-nav></setting-nav>
    <div class="content-container">

      <!-- Ödeme Bilgileri-->
      <section class="section">
        <div class="section-header-body" style="margin-top: 0px;">
          <h3 class="section-header">Şifre <span>Değiştir</span></h3>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[0]">
            <loader/>
          </div>

          <!--editable area-->
          <div class="password-form-body">
            <div class="hint-body">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>
              <p>Bazı <span>İpuçları</span> 😉</p>
              <div class="tooltiptext" style="visibility: visible">
                <help-box
                  description="<ul style='margin-left:20px;list-style:disc'><li>Yeni şifren <span style='font-weight: 800;'>en az 6, en çok 16</span> karakter uzunluğunda olmalıdır.</li><li style='margin-top:20px'>Başka web sitelerinde kullandığın parolaları tekrar kullanmamaya özen göster.</li><li style='margin-top: 20px;'><span style='font-weight: 800;'>Adın ya da e-postan</span> gibi belirgin sözcükleri tercih etmemeye çalış.</li></ul>"></help-box>
              </div>

            </div>
            <form class="password-form">
              <custom-input label="Geçerli Şifren" inputType="password" id="oldPassword" name="old-password" :valid="oldPasswordValidation" validText="Eski şifren hatalı. Tekrar dener misin?" v-model="oldPassword" style="width: 393px; margin-bottom: 47px;" autocomplete="new-password"/>
              <div style="position: relative;">
                <custom-input label="Yeni Şifren" inputType="password" id="password" name="password" maxlength="16" :valid="password && password.length > 5 ? true : null" v-model="password" style="width: 393px;" autocomplete="new-password"/>
                <p class="password-indicator-text" v-if="!!password"> {{this.strengthText}} </p>
              </div>
              <div class="strength-body" v-if="!!password">
                <div class="strength-indicator" :style="'background-color: ' + strengthColor"></div>
                <div class="strength-indicator" :style="strengthPower >= 2? 'background-color: ' + strengthColor : ''"></div>
                <div class="strength-indicator" :style="strengthPower >= 3? 'background-color: ' + strengthColor : ''"></div>
              </div>
              <custom-input label="Yeni Şifreni Tekrarla" inputType="password" id="rePassword" maxlength="16" name="new-password" :valid="rePasswordValidation" validText="Girdiğin şifreler birbiriyle eşleşmemektedir." v-model="rePassword" style="width: 393px; margin-top: 27px;" autocomplete="new-password"/>
            </form>
          </div>


          <div class="separator" style="margin-top: 30px;"></div>
          <div class="save-cancel-body">
            <custom-button buttonType="green" style="width: 210px;" :disabled="disableSaveButton(1)" @click="updatePassword()">Yeni Şifreni Belirle</custom-button>
          </div>
        </div>
      </section>

      <!-- Diğer Açık Oturumlar-->
      <section class="section" v-if="sessions.length">
        <div class="section-header-body">
          <h3 class="section-header">Diğer Açık <span>Oturumlar</span></h3>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[1]">
            <loader/>
          </div>

          <!--not editable area-->
          <div class="session-body" v-if="isEmpty(selectedSession)">

            <div v-for="(session,index) in sessions" :key="index" class="session-item">
              <div class="session-img-body">
                <img :src="session.icon_url" style="width: 40px; height: 40px;" alt="session-url"/>
              </div>

              <div class="session-info-label">
                <p>Giriş Zamanı</p>
                <p>Lokasyon</p>
                <p>Sistem</p>
                <p>IP</p>
              </div>
              <div class="session-info-value">
                <p>{{session.last_seen}}</p>
                <p>{{session.location}}</p>
                <p>{{session.system}}</p>
                <p>{{session.ip}}</p>
              </div>
              <div class="close-session-body" @click="selectSession(session)">
                <img src="https://gcdn.bionluk.com/site/c/ic-logout.svg" onload="SVGInject(this)" class="logout-img" style="margin-bottom: -2px;"/>
                Oturumu Kapat
              </div>
            </div>

            <custom-button buttonType="border" style="width: 210px; margin-top: 50px; align-self: center;" :disabled="disableSaveButton(2)" @click="getAllSession()" v-if="sessionCount > offset && sessionCount > sessions.length">Daha Fazla Göster</custom-button>
          </div>

          <!-- editable area-->
          <div class="session-body" v-else>

            <div class="session-item" >
              <div class="session-img-body">
                <img :src="selectedSession.icon_url" alt="session-url"/>
              </div>

              <div class="session-info-label">
                <p>Giriş Zamanı</p>
                <p>Lokasyon</p>
                <p>Sistem</p>
              </div>
              <div class="session-info-value">
                <p>{{selectedSession.last_seen}}</p>
                <p>{{selectedSession.location}}</p>
                <p>{{selectedSession.system}}</p>

              </div>
              <div style="width: 119px;"></div>
            </div>

            <div style="margin-top: 50px; display: flex; align-items: center;">
              <custom-input label="Geçerli Şifren" inputType="password" id="sessionPassword" name="sessionPassword" v-model="sessionPassword" style="width: 393px; margin-right: 15px;" autocomplete="new-password"/>
              <div data-balloon="Sol taraftaki alana geçerli şifreni girerek Bionluk’a giriş yapmış olduğun cihazdaki oturumu kapatabilirsin."
                   data-balloon-pos="right"
                   data-balloon-visible=""
                   data-balloon-length="large"
                   style="display: inline-flex; cursor: help; margin-left: 5px;">
                <img src="https://gcdn.bionluk.com/site/c/ic-info.svg" width="16px" height="16px"/>
              </div>
            </div>

          </div>

          <div class="separator" style="margin-top: 30px;"></div>
          <div class="save-cancel-body" v-if="isEmpty(selectedSession)">
            <custom-button buttonType="red" style="width: 280px;" :disabled="disableSaveButton(2)" @click="closeAllSessions()">Tüm Cihazlarda Oturumu Kapat</custom-button>
          </div>
          <div class="save-cancel-body" v-else>
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableSaveButton(2)" @click="initializeSelectedSession()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 280px;  margin-left: 30px;" :disabled="disableSaveButton(2, true)" @click="closeSession(selectedSession)">Bu Cihazda Oturumu Kapat</custom-button>
          </div>
        </div>
      </section>

    </div>
    <div class="grey-background"></div>
  </div>
</template>

<script>
  import SettingNav from "../navComponent";
  import CustomInput from "../../../components/CustomInput";
  import CustomButton from "../../../components/CustomButton";
  import helpBox from "../../../components/HelpBox";
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "src-pages-body-settings-security-v1",
    components: {
      SettingNav,
      CustomInput,
      CustomButton,
      helpBox
    },
    data() {
      return {
        requestWaiter: [
          false,
          false
        ],
        oldPassword: '',
        password: '',
        rePassword: '',
        oldPasswordValidation: null,
        rePasswordValidation: null,
        strengthText: 'Zayıf 😕',
        strengthColor: '#d8d8d8',
        strengthPower: 0,

        limit: 5,
        offset: 0,
        sessions: [],
        selectedSession: {},
        sessionPassword: null,
        sessionCount: 0
      }
    },
    methods: {
      isEmpty,
      updatePassword() {
        if(this.password !== this.rePassword) {
          this.rePasswordValidation = false;
          return;
        }
        this.handleRequestWaiter(1, true);
        this.api.user.settingsEditPassword(this.oldPassword, this.password, this.rePassword, false)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.oldPassword = '';
              this.password = '';
              this.rePassword = '';
            } else {
              this.oldPasswordValidation = false;
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(1);
          }).catch(err => {
          this.handleRequestWaiter(1);
          if (err.code !== "ECONNABORTED") {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        });
      },

      disableSaveButton(sectionId, closeSessionButton = false) {
        if (this.requestWaiter[sectionId - 1]) return true;
        switch (sectionId) {
          case 1:
            return this.oldPassword.length < 6 || this.password.length < 6 || this.rePassword.length < 6;
          case 2:
            if (closeSessionButton && this.sessionPassword && this.sessionPassword.length > 5) return false;
            else if (closeSessionButton) return true;
        }
      },

      handleRequestWaiter(sectionId, boolean = false) {
        const requestWaiter = [...this.requestWaiter];
        requestWaiter[sectionId - 1] = boolean;
        this.requestWaiter = [...requestWaiter];
      },

      validatePassword(pass) {

        let score = 0;
        if (!pass) {
          this.strengthText = 'Zayıf 😕';
          this.strengthColor = '#d8d8d8';
          return;
        }

        // award every unique letter until 5 repetitions
        let letters = new Object();
        for (let i = 0; i < pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1;
          score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        let variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /\W/.test(pass),
        };

        let variationCount = 0;
        for (let check in variations) {
          variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        const intScore = parseInt(score);

        if (intScore > 80) {
          this.strengthText = "Güçlü 😎";
          this.strengthColor = '#26ca9a';
          this.strengthPower = 3;
        } else if (intScore > 60) {
          this.strengthText = "Orta 🙄";
          this.strengthColor = '#ebbc00';
          this.strengthPower = 2;
        } else if (intScore >= 30) {
          this.strengthText = "Zayıf 😕";
          this.strengthColor = '#fd4056';
          this.strengthPower = 1;
        }

      },

      getAllSession() {
        this.handleRequestWaiter(2, true);
        this.api.user.getUserSessions(this.limit, this.offset)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.sessions = this.sessions.concat(result.data.sessions);
              this.offset += this.limit;
              this.sessionCount = result.data.count;
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(2);
          }).catch(err => {
          this.handleRequestWaiter(2);
          if (err.code !== "ECONNABORTED") {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        });
      },

      initializeSelectedSession() {
        this.selectedSession = {};
        this.sessionPassword = null;
      },

      selectSession(session) {
        this.selectedSession = {...session};
      },

      closeSession(session) {
        this.handleRequestWaiter(2, true);
        this.api.user.deleteSession(session.id, this.sessionPassword)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.sessions = this.sessions.filter(({ id }) => id !== session.id)
              this.initializeSelectedSession();
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(2);
          }).catch(err => {
          this.handleRequestWaiter(2);
          if (err.code !== "ECONNABORTED") {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        });
      },

      closeAllSessions() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CLOSE_SESSION});
      }
    },

    computed: {},

    watch: {
      password(newVal, oldVal) {
        this.validatePassword(newVal);
      },

      oldPassword(newVal) {
        if(newVal.length > 5)
          this.oldPasswordValidation = true;
      },

      rePassword(newVal) {
        if(newVal.length > 5)
          this.rePasswordValidation = true;
      }
    },

    created() {
      this.EventBus.$on('closeAllSessions', payload => {
        if (payload) {
          this.sessions = [];
        }
      });
      this.getAllSession();
    },

    beforeDestroy() {
      this.EventBus.$off('closeAllSessions');
    }

  }

</script>

<style scoped lang="scss">

  .settings-container {
    display: flex;
    min-height: calc(100vh - 111px);
    width: 1140px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
  }

  .content-container {
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 340px;
    z-index: 1;
    background-color: #f4f5f7;
    width: 100%;
    min-height: calc(100vh - 111px);
  }

  .grey-background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    min-height: calc(100vh);
    background-color: #f4f5f7;
  }

  .section {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .section-header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;

    .edit-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .edit-text {
        font-size: 14px;
        font-weight: 400;
        color: #8b95a1;
        margin-left: 5px;
      }
    }
  }

  .section-header {
    font-size: 24px;
    font-weight: 400;
    color: #4b4f52;

    span {
      font-weight: 600;
    }
  }

  .section-body {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #eaedf2;
    background-color: #fff;
    min-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .separator {
    border-bottom: 1px solid #eaedf2;
    width: 100%;
  }

  .save-cancel-body {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .strength-body {
    display: flex;
    align-items: center;
    width: 393px;
    margin-top: 10px;
  }

  .strength-indicator {
    width: 128px;
    height: 5px;
    border-radius: 1px;
    background-color: #d8d8d8;
    margin-left: 4px;

    &:first-child {
      margin-left: 0px;
    }
  }

  .password-form-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 60px;
  }

  .password-indicator-text {
    position: absolute;
    left: 403px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    font-size: 15px;
    font-weight: 400;
  }

  /*hint*/

  .hint-skill-icon /deep/ {
    width: 20px;
    height: 20px;

    g {
      fill: #5e6b79;
    }
  }

  .hint-body {
    position: relative;
    display: flex;
    align-items: center;
    cursor: help;

    p {
      margin-left: 5px;
      font-weight: 400;
      font-size: 20px;
      color: #4b4f52;

      span {
        font-weight: 600;
      }
    }

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .tooltiptext {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 270px;
    background-color: #2d3640;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 6px;
    z-index: 1;
    left: 0;
    top: 36px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  .tooltiptext::after {
    content: " ";
    position: absolute;
    left: 15px;
    bottom: 100%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #2d3640 transparent;
  }


  /*sessions*/
  .session-body {
    display: flex;
    flex-direction: column;
  }

  .session-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    border-top: 1px solid #eaedf2;

    &:first-child {
      border: none;
    }
  }

  .session-img-body {
    width: 60px;
    height: 60px;
    background-color: #f5f7fa;
    border: 1px solid #fafafa;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .session-info-label {
    width: 22.5%;
    color: #8b95a1;
    font-size: 16px;
    font-weight: 400;

    p {
      height: 30px;
    }
  }

  .session-info-value {
    width: 38.5%;
    color: #2d3640;
    font-size: 16px;
    font-weight: 400;

    p {
      height: 30px;
    }
  }

  .close-session-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #8b95a1;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .logout-img /deep/ {
    width: 14px;
    height: 16px;
    margin-right: 6px;

    path {
      fill: #8b95a1;
    }
  }

  .add-icon /deep/ {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-bottom: -2px;

    path {
      fill: #8b95a1;
    }
  }
</style>
